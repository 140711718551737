//*******************************************************}
//                                                       }
//             bloqs Cliëntvolgsysteem WebApp            }
//                                                       }
//     Copyright(c) 2019-2024 bloqssoftware b.v.         }
//              All rights reserved                      }
//                                                       }
//*******************************************************}

import React, { useEffect } from "react";
import { BrowserRouter as Router, useRoutes, useNavigate } from 'react-router-dom';
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import jssPreset from "@mui/styles/jssPreset";
import createTheme from "./theme";
import AppRoutes from "./routes";
import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import { AuthProvider } from "./contexts/AuthContext";

import { bConst } from "../src/constants";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  // const routescontent = useRoutes(routes); 
  const { theme } = useTheme();
  const navigate = useNavigate();

  console.log('BaseURL: '+process.env.REACT_APP_API_URL)

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault(); // prevent the default back button behavior
      navigate(bConst.rootLocation);
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <HelmetProvider>
        <Helmet
          titleTemplate="bloqs Cliëntvolgsysteem"
          defaultTitle="bloqs Cliëntvolgsysteem"
        />
        <Provider store={store}>
          {/* <StylesProvider jss={jss}> */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={createTheme(theme)}>
                  <ThemeProvider theme={createTheme(theme)}>

                    <AuthProvider>
                      <AppRoutes />
                    </AuthProvider>
                    
                  </ThemeProvider>
                </MuiThemeProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          {/* </StylesProvider> */}
        </Provider>
      </HelmetProvider>
    
  );
}

export default App;
